import { colors } from '@material-ui/core';

export const breadcrumb = [
  {
    href: '#',
    title: 'Help Center',
    isActive: false,
  },
  {
    href: '#',
    title: 'Articles',
    isActive: false,
  },
  {
    title: 'Account',
    isActive: true,
  },
];

export const questions = {
  title: 'Account',
  subtitle: 'Let’s try to fix your account issues.',
  icon: 'fas fa-user',
  color: colors.amber,
  items: [
    {
      id: 'faq-1',
      title: 'Ошибка при получении местоположения на Андройд?',
      subtitle: 'Если при нажатии кнопки "получить" местоположение не определяется и появляется ошибка...',
      text: 'В этом случае стоит проверить настройки приложения: должно стоять разрешение на определение местопложения (Настройки, Приложения, СтарЧат)',
      imageUrl: "location_error.jpg",
      link: '',
    },
    {
      id: 'faq-2',
      title: 'Как настроить разрешение в настройках Андройда?',
      subtitle: 'Разрешения для определения местоположения',
      text:
        'На этом снимке показано как для СтарЧат приложения на Андройде не разрешено получение местоположения. Если вы дадите разрешение, но местоположение так и не определяется - попробуйте выйти на улицу или подойти ко кну (если в здании).',
      imageUrl: "permissions.jpg",
      link: '',
    },
    {
      id: 'faq-3',
      title: "Что если СтарЧат не найти в поиске магазина (Google Play или App Store)?",
      subtitle: '',
      text:
        'Проверьте вашу страну - старчат работает только в России, Казахстане, Киргизии и Украине (пока 😇). Если страна в списке, а приложения нет в поиске - попробуйте по прямой ссылке - Https://play.google.com/store/apps/details?id=best.supercool.booq (Андройд), https://apps.apple.com/ru/app/starchat/id1478690076 (Айфон). Если приложение так и не удалось установить, обратитесь пожалуйста в поддержку.',
      link: 'Обратится в поддержку',
      href: 'https://t.me/scsupport'
    },
    {
      id: 'faq-4',
      title: "Бонус за приглашенного не зачислился?",
      subtitle: 'Что если пользователь меня добавил как знакомого, а звезды мне не добавились?',
      text:
      'Во-первых, удостоверьтесь, что пользователь добавил вас как знакомого первым. Во-вторых, бонус дается только за реальных пользователей с включенными уведомлениями (нотификацией). Если пользователь отклонил просьбу СтарЧат на получение уведомлений сразу после установки приложения, мы считаем что он установил приложение исключительно для бонуса, а не для общения и не даем за него бонус. В-третьих, проверьте движения по звездному счету нажав на счетчик звезд - возможно бонус за пользователя был добавлен, но когда пользователь удалился он был вычтен. Обратитесь в поддержку, если бонус не начислился и причина не ясна.',
      link: 'Обратится в поддержку',
      href: 'https://t.me/scsupport'
    },
    {
      id: 'faq-4',
      title: "Куда делись мои звезды?",
      subtitle: 'Я приглашал пользователей, получал звезды, а потом они пропали...',
      text:
        'СтарЧат дает звезды только за приглашение пользователей (а не регистрацию аккаунта). Поэтому если пользователь указал вас как пригласившего, после чего не общался и удалил приложение (или аккаунт), СтарЧат оставляет за собой право забрать бонус обратно. Чтобы понять причину списания звезд вы можете просмотреть движения по звездному счету нажав на счетчик звезд. Если приглашенный пользователь не удалялся, а звезды за него ушли - обратитесь в поддержку.',
        link: 'Обратится в поддержку',
      href: 'https://t.me/scsupport'
    },
  ],
};
export const categories = [
  {
    title: 'Billing',
    subtitle: 'Let’s try to fix your billing issues.',
    icon: 'fas fa-dollar-sign',
    color: colors.indigo,
  },
  {
    title: 'Customizing',
    subtitle: 'Let’s try to fix your customizing issues.',
    icon: 'fas fa-puzzle-piece',
    color: colors.blue,
  },
  {
    title: 'Organizations',
    subtitle: 'Let’s try to fix your organizational issues.',
    icon: 'fas fa-users',
    color: colors.deepPurple,
  },
];
